.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  padding: 0 20px; /* Add padding for mobile view */
}

.hero .background-animation {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(26, 26, 46, 0.9), #0f3460);
  animation: moveBg 8s linear infinite;
  z-index: -1;
}



#hero-tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure particles are behind hero content */
  pointer-events: none;
}


.hero-title {
  font-size: 3rem; /* Reduced for smaller screens */
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #61dafb, #21a1f1, #61dafb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-description {
  font-size: 1.2rem; /* Adjusted for readability on smaller screens */
  color: #dcdcdc;
  margin-bottom: 1.5rem;
}

.hero-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; /* Adjusted gap for smaller screens */
  margin: 2rem 0;
  flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
}

.icon-button {
  background: #282c34;
  color: white;
  border: 2px solid #61dafb;
  padding: 8px 15px; /* Adjusted padding for smaller buttons */
  font-size: 1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0px 0px 10px rgba(97, 218, 251, 0.6);
}

.icon-button i {
  font-size: 1.2rem; /* Adjusted icon size */
}

.icon-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 0px 20px rgba(97, 218, 251, 0.9);
}

.hero-subtext {
  font-size: 1rem; /* Adjusted font size */
  color: #b3b3b3;
  margin: 1rem 0 2rem;
  line-height: 1.6;
  max-width: 90%; /* Reduced width for smaller screens */
}

.cta-button {
  background: linear-gradient(90deg, #21a1f1, #61dafb);
  color: #ffffff;
  border: none;
  padding: 12px 25px; /* Adjusted padding for smaller screens */
  font-size: 1rem; /* Adjusted font size */
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 10px rgba(97, 218, 251, 0.6);
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 0px 20px rgba(97, 218, 251, 0.9);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-description {
    font-size: 1rem;
  }

  .hero-icons {
    gap: 0.8rem;
  }

  .icon-button {
    font-size: 0.9rem;
    padding: 8px 12px;
  }

  .icon-button i {
    font-size: 1rem;
  }

  .cta-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .hero-subtext {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2rem;
  }

  .hero-description {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .hero-icons {
    flex-direction: column; /* Stack buttons vertically */
    gap: 0.5rem;
  }

  .icon-button {
    padding: 6px 10px;
    font-size: 0.8rem;
  }

  .icon-button i {
    font-size: 0.9rem;
  }

  .cta-button {
    padding: 8px 15px;
    font-size: 0.8rem;
  }

  .hero-subtext {
    font-size: 0.8rem;
  }
}

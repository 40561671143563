.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  padding: 15px 25px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  font-size: 1rem;
  color: #ffffff;
  background: linear-gradient(135deg, #0d1117, #161b22);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  animation: slide-in 0.4s ease-out, fade-out 0.4s ease-in 4.6s forwards;
  overflow: hidden;
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Success Notification */
.notification.success {
  border-left: 4px solid #4caf50;
  background: linear-gradient(135deg, #1f4037, #99f2c8);
}

/* Error Notification */
.notification.error {
  border-left: 4px solid #f44336;
  background: linear-gradient(135deg, #452a2a, #ff6961);
}

/* Close Button */
.notification button {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.notification button:hover {
  background: rgba(255, 255, 255, 0.4);
}

/* Slide-in Animation */
@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(-30px) translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
}

/* Fade-out Animation */
@keyframes fade-out {
  to {
    opacity: 0;
    transform: translateY(-30px) translateX(30px);
  }
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .notification {
    top: auto;
    bottom: 20px;
    right: 10px;
    left: 10px;
    padding: 10px 15px;
    font-size: 0.9rem;
  }

  .notification button {
    width: 20px;
    height: 20px;
    font-size: 1rem;
  }
}

/* General Styles */
.about {
  font-family: "Inter", sans-serif;
  color: white;
  background: #1a1a2e;
  margin: 0;
  padding: 0;
}

/* Hero Section */
.about-hero {
  text-align: center;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #1a1a2e, #61dafb);
}

.hero-content {
  max-width: 800px;
  text-align: center;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: bold;
  color: white;
}

.hero-description {
  font-size: 1.3rem;
  color: #dcdcdc;
  margin-top: 10px;
}

/* Section Titles */
.section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  background: linear-gradient(135deg, #61dafb, #21a1f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Key Values */
.key-values {
  background: #161b22;
  padding: 60px 20px;
}

.values-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.value-card {
  background: linear-gradient(145deg, #1f2630, #2a313c);
  color: white;
  padding: 20px;
  border-radius: 15px;
  width: 300px;
  box-shadow: 0px 4px 15px rgba(97, 218, 251, 0.3);
  text-align: center;
}

.value-icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

.innovation-icon {
  color: #fdd835; /* Yellow for innovation */
}

.security-icon {
  color: #4caf50; /* Green for security */
}

.community-icon {
  color: #1e88e5; /* Blue for community */
}

/* Features Section */
.features {
  background: #1a1f26;
  padding: 60px 20px;
}

.features-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.feature-card {
  background: #282c34;
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

.ecosystem-icon {
  color: #8e44ad; /* Purple for ecosystem */
}

.tools-icon {
  color: #ff6f61; /* Coral for tools */
}

.scalability-icon {
  color: #00acc1; /* Cyan for scalability */
}

.feature-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 20px rgba(97, 218, 251, 0.4);
}

/* Call-to-Action Section */
.cta-section {
  background: linear-gradient(135deg, #61dafb, #1a1a2e);
  text-align: center;
  padding: 60px 20px;
  height: 30vh;
}

.cta-title {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 20px;
}

.cta-description {
  font-size: 1.2rem;
  color: #dcdcdc;
  max-width: 700px;
  margin: 0 auto 30px;
}

.cta-button {
  background: white;
  color: #1a1a2e;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.cta-button:hover {
  transform: translateY(-2px);
  background: #61dafb;
  color: white;
  box-shadow: 0px 6px 20px rgba(97, 218, 251, 0.6);
}

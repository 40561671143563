.burn-mint-metrics {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}

.burn-mint-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

.metrics-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 900px;
}

.metric-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  width: 200px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background 0.3s ease;
}

.metric-card:hover {
  transform: translateY(-10px);
  background: rgba(255, 255, 255, 0.2);
}

.metric-icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

.metric-label {
  font-size: 1.2rem;
  margin-bottom: 5px;
  color: #61dafb;
}

.metric-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.burned .metric-icon {
  color: #ff6f61;
}

.minted .metric-icon {
  color: #4caf50;
}

.price .metric-icon {
  color: #ffa500;
}

/* Countdown Widget Styles */
.countdown-widget {
  background: linear-gradient(135deg, rgba(26, 26, 46, 0.7), rgba(40, 44, 52, 0.7));
  color: #ffffff;
  text-align: center;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.5);
  margin: 20px auto;
  max-width: 500px;
  backdrop-filter: blur(10px);
}

.widget-title {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #61dafb; /* Web3 cyan */
  margin-bottom: 20px;
}

.widget-icon {
  margin-right: 10px;
  font-size: 1.5rem;
}

.countdown-timer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.time-segment {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7);
}

.time {
  font-size: 3rem;
  font-weight: bold;
  color: #ffa500; /* Gold-like color */
}

.label {
  font-size: 1rem;
  color: #dcdcdc;
  margin-top: 5px;
}

/* Mining Info */
.mining-info {
  margin-top: 20px;
}

.mining-info p {
  font-size: 1.2rem;
  color: #dcdcdc;
  margin-bottom: 10px;
}

.mine-button {
  background: #61dafb;
  color: #1a1a2e;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.mine-button:hover {
  background: #21a1f1;
  transform: scale(1.05);
}

.button-icon {
  font-size: 1.2rem;
}

/* Launch Message */
.launch-message {
  font-size: 1.5rem;
  color: #4caf50; /* Green for success */
}

/* Responsive Design */
@media (max-width: 768px) {
  .time {
    font-size: 2.5rem;
  }

  .label {
    font-size: 0.8rem;
  }

  .widget-title {
    font-size: 1.5rem;
  }
}

/* General Styles for Both Desktop and Mobile */
.dashboard-nav .nav-list,
.dashboard-nav .nav-list-mobile {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dashboard-nav .nav-list li,
.dashboard-nav .nav-list-mobile li {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Stack text below icon */
  gap: 5px;
  padding: 10px 15px;
  color: #dcdcdc;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.dashboard-nav .nav-list li:hover,
.dashboard-nav .nav-list-mobile li:hover {
  color: #61dafb;
  transform: translateY(-2px);
}

.dashboard-nav .nav-icon,
.dashboard-nav .nav-icon-mobile {
  font-size: 1.5rem;
}

/* Logout Icon in Red */
.dashboard-nav .logout-icon {
  color: #ff4d4d; /* Red color for logout */
}

/* Desktop Navigation */
.dashboard-nav .nav-desktop {
  width: flex;
  background-color: #1a1a2e;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dashboard-nav .nav-list {
  display: flex;
  justify-content: space-between; /* Ensure items are spaced */
  gap: 20px; /* Add gap between list items */
  max-width: 1200px; /* Optional: constrain width for better spacing */
  flex-wrap: wrap; /* Ensure wrapping if content overflows */
}

/* Mobile Navigation */
.dashboard-nav .nav-mobile {
  display: none;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #1a1a2e;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.dashboard-nav .nav-list-mobile {
  display: flex;
  justify-content: space-around; /* Space items evenly */
  align-items: center;
  flex-wrap: nowrap; /* Prevent wrapping */
  padding: 0 15px;
}

/* Overlay Loader for Logout */
.overlay-logout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.logout-loader {
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard-nav .nav-desktop {
    display: none;
  }

  .dashboard-nav .nav-mobile {
    display: flex;
  }

  .dashboard-nav .nav-list-mobile li {
    flex: 1; /* Spread items evenly */
    text-align: center;
  }

  .dashboard-nav .nav-icon-mobile {
    font-size: 1.2rem; /* Slightly smaller icons for mobile */
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Full-page overlay for logout */
.overlay-logout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Logout loader styles */
.logout-loader {
  text-align: center;
  color: #ffffff;
  animation: fadeIn 0.5s ease-in-out;
}

.logout-loader p {
  margin-top: 10px;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Spinner icon animation */
.spinner-icon {
  font-size: 3rem;
  color: #61dafb;
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


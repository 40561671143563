/* General Header Styles */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding: 10px 0;
  background: var(--header-bg-color, rgba(0, 0, 0, 0.9)); /* Default to transparent black if no background is found */
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); /* Optional smooth blur effect */
  transition: background 0.3s ease;
}

.nav {
  display: flex;
  justify-content: center;
}

.nav-list {
  display: flex;
  list-style: none;
  gap: 20px; /* Space between nav items */
  margin-right: 20px;
}

.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  font-size: 0.9rem;
  color: #dcdcdc;
  transition: transform 0.3s, color 0.3s;
  cursor: pointer;
}

.nav-link i {
  font-size: 1.5rem;
  margin-bottom: 5px; /* Space between icon and label */
}

.nav-link span {
  font-size: 0.9rem;
  text-transform: uppercase;
}

.nav-link:hover {
  color: #61dafb;
  transform: translateY(-3px);
}

/* Highlight active link */
.nav-link.active {
  color: #61dafb;
  font-weight: bold;
} /* <-- This closing brace was missing */

/* Responsive Design */
@media (max-width: 768px) {
  .nav-list {
    gap: 20px;
  }

  .nav-link i {
    font-size: 1.2rem;
  }

  .nav-link span {
    font-size: 0.8rem;
  }
}

/* Login Button Styles */
.login-button {
  background: none;
  border: none;
  color: #dcdcdc;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color 0.3s, transform 0.3s;
}

.login-button i {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.login-button span {
  font-size: 0.9rem;
  text-transform: uppercase;
}

.login-button:hover {
  color: #61dafb;
  transform: translateY(-3px);
}


.dashboard-home {
    text-align: center;
    padding: 20px;
    background: linear-gradient(135deg, #1a1a2e, #282c34); /* Dark gradient background */
    color: #ffffff;
    min-height: 100vh;
  }
  
  .dashboard-home h1 {
    font-size: 2.5rem;
    color: #61dafb; /* Light blue title */
    margin-bottom: 20px;
    text-shadow: 0 2px 5px rgba(97, 218, 251, 0.5);
  }
  
/* Dashboard Container */
.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  background: linear-gradient(135deg, #1a1a2e, #282c34); /* Gradient background */
  color: #ffffff;
  font-family: "Inter", sans-serif;
}

/* Dashboard Content Area */
.dashboard-content {
  flex: 1; /* Take up remaining space */
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Buttons (Optional Styles) */
.dashboard-content button {
  background: #61dafb;
  color: #1a1a2e;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.dashboard-content button:hover {
  background: #21a1f1;
}

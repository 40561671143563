/* General Styles */
.mining-page {
  font-family: "Inter", sans-serif;
  color: white;
  background: #1a1a2e;
  margin: 0;
  padding: 0;
}

/* Hero Section */
.mining-hero {
  text-align: center;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #1a1a2e, #61dafb);
}

.hero-content {
  max-width: 800px;
  text-align: center;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: bold;
  color: white;
}

.hero-description {
  font-size: 1.3rem;
  color: #dcdcdc;
  margin-top: 10px;
}

/* Mining Stats */
.mining-stats {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  flex-wrap: wrap;
  padding: 20px;
}

.stat-card {
  background: #0f172a;
  padding: 20px;
  border-radius: 15px;
  width: 300px;
  text-align: center;
  box-shadow: 0px 4px 15px rgba(97, 218, 251, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 8px 20px rgba(97, 218, 251, 0.5);
}

.stat-icon {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #61dafb;
}

.connect-wallet-btn,
.start-mining-btn {
  background: #61dafb;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: #0f172a;
  font-weight: bold;
  cursor: pointer;
}

.connect-wallet-btn:hover,
.start-mining-btn:hover {
  background: #21a1f1;
}

/* Instructions Section */
.mining-instructions {
  padding: 40px;
  background: #161b22;
  color: #dcdcdc;
}

.instruction-list {
  list-style: none;
  padding: 0;
}

.instruction-list li {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}

.instruction-icon {
  font-size: 2rem;
  color: #61dafb;
}

.instruction-note {
  margin-top: 20px;
  font-style: italic;
  color: #b3b3b3;
}


.connection-status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px auto;
}

.connection-status.red {
  background-color: red;
}

.connection-status.yellow {
  background-color: yellow;
}

.connection-status.green {
  background-color: green;
}

.stat-card {
  background: #1a1f26;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  color: white;
  width: 300px;
  margin: 10px;
}

.connect-wallet-btn,
.start-mining-btn,
.claim-btn {
  background-color: #61dafb;
  color: #0f172a;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  margin-top: 10px;
}

.connect-wallet-btn:hover,
.start-mining-btn:hover,
.claim-btn:hover {
  background-color: #21a1f1;
}

.status-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.red-dot {
  background-color: red;
}

.yellow-dot {
  background-color: yellow;
}

.green-dot {
  background-color: green;
}

.claim-tokens-btn {
  background-color: #61dafb;
  color: #0f172a;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.claim-tokens-btn:hover {
  background-color: #21a1f1;
  color: #ffffff;
}

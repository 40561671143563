.airdrop-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 16px; /* Space between sections */
  width: 100%;
}

.airdrop-list,
.airdrop-create,
.airdrop-details {
  flex: 1; /* Equal size */
  min-width: 280px; /* Minimum size for responsiveness */
  max-width: 33%; /* Adjust for desktop */
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .airdrop-list,
  .airdrop-create,
  .airdrop-details {
    max-width: 100%; /* Stack sections on small screens */
    flex: 1 1 100%; /* Full width on mobile */
  }
}

/* Roadmap Section */
.roadmap {
  padding: 50px 20px;
  background: linear-gradient(135deg, #1e1e2f, #282c34);
  color: white;
  text-align: center;
}

.roadmap-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  background: linear-gradient(135deg, #61dafb, #21a1f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.roadmap-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.step {
  background: #161b22;
  color: #61dafb;
  padding: 30px;
  border-radius: 15px;
  width: 280px;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
}

.step:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.icon {
  font-size: 3rem;
  margin-bottom: 15px;
}

.step h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: white;
}

.step p {
  font-size: 1rem;
  color: #b3b3b3;
}

.token-allocation-container {
  background: linear-gradient(135deg, #1e293b, #0f172a);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.allocation-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.allocation-bars {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.allocation-bar-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.allocation-bar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.allocation-icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.allocation-bar-header h3 {
  font-size: 1.2rem;
  margin: 0;
}

.allocation-bar-header p {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.allocation-bar-background {
  height: 15px;
  border-radius: 10px;
  background-color: #61dafb;
  transition: width 0.5s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .allocation-bar-header h3 {
    font-size: 1rem;
  }

  .allocation-bar-header p {
    font-size: 0.9rem;
  }
}

/* Submit Proposal Section */
.submit-proposal {
    padding: 30px;
    background: #1a1f26;
    border-radius: 10px;
    margin: 20px auto 50px; /* Add bottom margin for spacing */
    max-width: 800px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  }
  
  .submit-proposal h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    text-align: center;
    background: linear-gradient(135deg, #61dafb, #21a1f1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .submit-proposal p {
    font-size: 1.2rem;
    color: #dcdcdc;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .submit-proposal form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .submit-proposal label {
    font-size: 1rem;
    color: #ffffff;
  }
  
  .submit-proposal input,
  .submit-proposal textarea {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #61dafb;
    background: #0f172a;
    color: white;
    width: 100%;
    outline: none;
    resize: none; /* Prevent textarea resize */
  }
  
  .submit-proposal input:focus,
  .submit-proposal textarea:focus {
    border-color: #21a1f1;
    box-shadow: 0px 0px 5px #21a1f1;
  }
  
  .submit-proposal button {
    padding: 10px 20px;
    font-size: 1rem;
    background: #61dafb;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .submit-proposal button:hover {
    background: #21a1f1;
  }
  
  .submit-proposal button:active {
    transform: scale(0.95);
  }
  
  .submit-proposal .button-icon {
    font-size: 1.2rem;
  }
  
  .submit-proposal .success-message {
    font-size: 1.2rem;
    color: #4caf50;
    margin-top: 15px;
    text-align: center;
  }
  
  .submit-proposal .error-message {
    font-size: 1.2rem;
    color: #f44336;
    margin-top: 15px;
    text-align: center;
  }
  
/* General Page Styling */
.invest-page {
    font-family: "Inter", sans-serif;
    color: #ffffff;
    background: #1a1a2e;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Ensure no horizontal scroll issues */
  }
  
  /* Hero Section */
  .invest-hero {
    text-align: center;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #1a1a2e, #61dafb);
    padding: 20px;
    margin-bottom: 30px;
    position: relative;
  }
  
  .invest-hero h1 {
    font-size: 3.5rem;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
  }
  
  .invest-hero p {
    font-size: 1.2rem;
    color: #dcdcdc;
    max-width: 800px;
  }
  
  /* Remove Rogue Dollar Sign */
  .invest-hero::after {
    content: ""; /* Ensure no unwanted pseudo-element */
    display: none;
  }
  
  /* Investment Details Section */
  .investment-details {
    padding: 30px;
    background: #161b22;
    border-radius: 10px;
    margin: 20px auto;
    max-width: 800px;
  }
  
  /* Profit Calculator Section */
  .profit-calculator {
    padding: 30px;
    background: #1a1f26;
    border-radius: 10px;
    margin: 20px auto 50px; /* Add bottom margin to prevent overlap with footer */
    max-width: 800px;
    text-align: center;
  }
  
  .profit-calculator label {
    font-size: 1.1rem;
    color: #ffffff;
    display: block;
    margin-bottom: 10px;
  }
  
  .profit-calculator input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #61dafb;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .profit-calculator button {
    padding: 10px 20px;
    font-size: 1rem;
    background: #61dafb;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .profit-calculator button:hover {
    background: #21a1f1;
  }
  
  .profit-calculator p {
    font-size: 1.2rem;
    color: #dcdcdc;
    margin-top: 15px;
  }
  
  /* Footer Fix */
  footer {
    background: #0f1624;
    color: #ffffff;
    padding: 30px 20px;
    text-align: center;
  }
  
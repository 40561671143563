/* General Footer Brand Styling */
.footer-brand {
  flex: 1;
  text-align: left;
  min-width: 300px;
  padding: 20px;
}

.footer-brand-title {
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(135deg, #61dafb, #21a1f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
}

.footer-brand-tagline {
  font-size: 1.3rem;
  color: #b3b3b3;
  margin: 15px 0;
  line-height: 1.6;
}

/* Brand Icons Section */
.footer-brand-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.brand-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;
  color: #ffffff;
  transition: transform 0.3s ease, color 0.3s ease;
}

.brand-icon:hover {
  transform: translateY(-5px);
}

.brand-icon span {
  font-size: 1rem;
  color: #dcdcdc;
}

/* Individual Icon Colors */
.icon {
  font-size: 2.5rem;
}

.icon.innovation {
  color: #61dafb;
}

.icon.global {
  color: #28a745; /* Green for global reach */
}

.icon.community {
  color: #ff6f61; /* Red for community */
}

.icon.security {
  color: #ffc107; /* Yellow for security */
}

.icon.partnerships {
  color: #6f42c1; /* Purple for partnerships */
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-brand-icons {
    gap: 10px;
    justify-content: center;
  }

  .brand-icon {
    flex: 1 1 calc(50% - 10px); /* Two icons per row */
  }

  .footer-brand-title {
    font-size: 2.5rem;
  }

  .footer-brand-tagline {
    font-size: 1rem;
  }
}

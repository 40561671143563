/* Modal Container */
.airdrop-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: #1a1a2e;
  padding: 20px 30px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.9);
  color: #ffffff;
  width: 90%;
  max-width: 500px;
  position: relative;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Modal Header */
.modal-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.modal-header-icon {
  font-size: 2rem;
  color: #61dafb;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 2px 5px rgba(97, 218, 251, 0.5);
}

/* Modal Body */
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal-item {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #101020;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.modal-icon {
  font-size: 1.5rem;
  color: #61dafb;
}

.modal-item p {
  margin: 0;
  font-size: 1rem;
  color: #dcdcdc;
}

.modal-item strong {
  color: #61dafb;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.action-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.stop-btn {
  background-color: #ff6347;
  color: #fff;
}

.stop-btn:hover {
  background-color: #ff4500;
}

.restart-btn {
  background-color: #32cd32;
  color: #fff;
}

.restart-btn:hover {
  background-color: #228b22;
}

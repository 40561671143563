.total-supply-container {
  background: linear-gradient(135deg, #1e293b, #0f172a);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  max-width: 800px; /* Allow more space for side-by-side layout */
  margin: 20px auto;
  text-align: center;
}

.supply-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.title-icon {
  color: #61dafb;
  font-size: 2.5rem;
}

.supply-details {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Enables wrapping on smaller screens */
}

.supply-card {
  background: #0f172a;
  padding: 15px 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #ffffff;
  flex: 1 1 calc(50% - 20px); /* Side-by-side layout on larger screens */
  max-width: 300px; /* Limit width for consistency */
  min-width: 240px; /* Prevent cards from shrinking too much */
}

.supply-card:hover {
  box-shadow: 0 8px 20px rgba(97, 218, 251, 0.5);
  transform: translateY(-5px);
}

.supply-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #4caf50;
}

.supply-card p {
  font-size: 1.2rem;
  margin: 5px 0;
}

.supply-card span {
  font-size: 1.5rem;
  font-weight: bold;
  color: #61dafb;
}

/* Responsive Design for Smaller Screens */
@media screen and (max-width: 768px) {
  .supply-details {
    flex-direction: column; /* Stack cards vertically */
    gap: 20px;
  }

  .supply-card {
    flex: 1 1 100%; /* Cards take full width on smaller screens */
    max-width: none; /* Remove max-width for full responsiveness */
  }
}

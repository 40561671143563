/* General Footer Social Styling */
.footer-social {
  flex: 1;
  min-width: 250px;
  text-align: center;
}

.footer-social h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #61dafb;
}

/* Social Icons Section */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.social-icon {
  font-size: 2rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

/* True Colors for Social Icons */
.twitter {
  color: #1da1f2; /* Twitter blue */
}

.telegram {
  color: #0088cc; /* Telegram blue */
}

.github {
  color: #ffffff; /* GitHub white */
}

.facebook {
  color: #1877f2; /* Facebook blue */
}

.linkedin {
  color: #0077b5; /* LinkedIn blue */
}

/* Hover Animation for Social Icons */
.social-icon:hover {
  transform: scale(1.2);
}

/* Sponsorship Section */
.sponsorship-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.partner-icon {
  font-size: 2rem;
  transition: transform 0.3s ease;
}

/* True Colors for Partner Icons */
.mastercard .sponsorship-icon {
  color: #ff5f00; /* Mastercard orange */
}

.binance .sponsorship-icon {
  color: #f0b90b; /* Binance yellow */
}

.bitcoin .sponsorship-icon {
  color: #f7931a; /* Bitcoin orange */
}

.ethereum .sponsorship-icon {
  color: #3c3c3d; /* Ethereum dark gray */
}

.solana .sponsorship-icon {
  color: #9945ff; /* Solana purple gradient */
}

/* Partner Icons Hover Effect */
.partner-icon:hover {
  transform: scale(1.2);
}

.airdrop-create {
    background: #1a1a2e;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
    color: #ffffff;
    text-align: center;
    
  }
  
  .airdrop-create h2 {
    font-size: 1.8rem;
    color: #61dafb;
    margin-bottom: 20px;
    text-shadow: 0 2px 5px rgba(97, 218, 251, 0.5);
  }
  
  .airdrop-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    background: #0f172a;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    color: #ffffff;
  }
  
  .form-icon {
    margin-right: 10px;
    font-size: 1.2rem;
    color: #61dafb;
  }
  
  input {
    flex: 1;
    background: transparent;
    border: none;
    outline: none;
    color: #ffffff;
    font-size: 1rem;
    padding: 5px;
  }
  
  input::placeholder {
    color: #dcdcdc;
  }
  
  .submit-btn {
    background: #61dafb;
    color: #1a1a2e;
    border: none;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.3s;
  }
  
  .submit-btn:disabled {
    background: #3b82f6;
    cursor: not-allowed;
  }
  
  .submit-btn:hover {
    background: #21a1f1;
    transform: scale(1.05);
  }
  
  .success-message {
    margin-top: 15px;
    background: #1a1a2e;
    color: #61dafb;
    padding: 10px 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .success-icon {
    font-size: 1.2rem;
  }
  
  .error-message {
    background: #e63946;
    color: #fff;
    padding: 10px;
    margin: 15px 0;
    border-radius: 5px;
    font-size: 0.9rem;
    text-align: center;
  }

  
  
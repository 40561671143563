/* General FAQ Styles */
.faqs {
  background: linear-gradient(135deg, #1a1a2e, #161b22); /* Matching Tokenomics Gradient */
  color: white;
  padding: 0;
  font-family: "Inter", sans-serif;
}

/* Hero Section */
.faqs-hero {
  text-align: center;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #1a1a2e, #61dafb); /* Matching Tokenomics Gradient */
  padding: 20px;
}

.faqs-hero-title {
  font-size: 3.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 15px;
}

.faqs-hero-description {
  font-size: 1.3rem;
  color: #dcdcdc;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  text-align: center;
}

/* FAQ Section */
.faq-content {
  padding: 40px 20px;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq {
  background: #1a1f26;
  padding: 15px 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 15px rgba(97, 218, 251, 0.3); /* Matching box shadow */
  transition: all 0.3s ease;
}

.faq.active {
  border-left: 5px solid #61dafb; /* Active indicator */
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.2rem;
  color: white;
}

.faq-icon {
  font-size: 1.5rem;
  color: #61dafb; /* Icon color matches theme */
  margin-right: 10px;
}

.faq-answer {
  padding-top: 10px;
  color: #dcdcdc;
  font-size: 1rem;
  line-height: 1.5;
  overflow: hidden;
}

/* Framer Motion Animation Styles */
.faq-answer p {
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .faqs-hero-title {
    font-size: 2.5rem;
  }

  .faqs-hero-description {
    font-size: 1rem;
  }

  .faq-question {
    font-size: 1rem;
  }
}

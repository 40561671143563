.staking-calculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  max-width: 400px;
  margin: 0 auto;
}

.staking-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.title-icon {
  color: #61dafb;
  font-size: 2.5rem;
}

.calculator-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.input-container {
  position: relative;
  width: 90%;
  margin-right: 45px;
}

.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: #61dafb;
}

.staking-input {
  width: 100%;
  padding: 10px 15px 10px 40px; /* Adjust padding to fit the icon */
  border-radius: 10px;
  border: none;
  font-size: 1rem;
  outline: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

.staking-input:focus {
  box-shadow: 0 4px 15px rgba(97, 218, 251, 0.6);
}

.reward-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reward-icon {
  font-size: 2rem;
  color: #4caf50;
}

.staking-reward {
  font-size: 1.2rem;
  text-align: center;
  color: #ffffff;
}

.reward-value {
  font-weight: bold;
  font-size: 1.5rem;
  color: #4caf50;
}

.staking-note {
  font-size: 0.9rem;
  text-align: center;
  color: #b0bec5;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .staking-calculator {
    padding: 15px;
    max-width: 90%;
  }

  .staking-title {
    font-size: 1.5rem;
  }

  .staking-input {
    font-size: 0.9rem;
  }

  .staking-reward {
    font-size: 1rem;
  }
}

.invest-wallet {
  background: #1a1a2e;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
  color: #ffffff;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.wallet-icon {
  font-size: 1.5rem;
  color: #61dafb;
}

.wallet-info {
  margin-top: 10px;
}

.wallet-address {
  font-size: 1rem;
  color: #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.copy-btn {
  background: none;
  border: none;
  color: #61dafb;
  cursor: pointer;
}

.copy-btn:hover {
  color: #21a1f1;
}

.wallet-balance {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #61dafb;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.eth-icon {
  font-size: 1.5rem;
}

.connect-wallet-btn {
  background: #61dafb;
  color: #1a1a2e;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.connect-wallet-btn:hover {
  background: #21a1f1;
}

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  animation: blink 1.5s infinite;
}

.status-dot.green {
  background-color: #00ff00;
}

.status-dot.red {
  background-color: #ff0000;
}

.status-text {
  font-size: 0.9rem;
  color: #dcdcdc;
}

@keyframes blink {
  50% {
    opacity: 0.5;
  }
}

.error-message {
  margin-top: 15px;
  color: #ff0000;
  font-size: 0.9rem;
}

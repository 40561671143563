/* Scoped Styles for Profit Calculator */
.profit-calculator {
  font-family: "Inter", sans-serif;
  background: linear-gradient(135deg, #1a1a2e, #0f172a);
  color: white;
  padding: 40px 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  margin: 30px auto;
}

.calculator-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  background: linear-gradient(135deg, #61dafb, #21a1f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.title-icon {
  font-size: 2rem;
}

.calculator-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.calculator-label {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.calculator-input-icon {
  font-size: 1.5rem;
  color: #61dafb;
}

.calculator-input {
  padding: 10px 15px;
  font-size: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  background: #0f172a;
  color: white;
  outline: none;
  width: 100%;
  max-width: 300px;
}

.calculator-input:focus {
  border-color: #61dafb;
}

.calculator-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background: #61dafb;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background 0.3s ease;
}

.calculator-button:disabled {
  cursor: not-allowed;
  background: rgba(97, 218, 251, 0.6);
}

.calculator-button:hover {
  background: #21a1f1;
}

.calculator-button:active {
  background: #1a73e8;
}

.calculator-button-icon {
  font-size: 1.2rem;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.calculator-results {
  margin-top: 20px;
  text-align: center;
}

.calculator-results h3 {
  font-size: 1.5rem;
  color: #61dafb;
}

.calculator-results p {
  font-size: 1.2rem;
  color: #dcdcdc;
}

.calculator-results ul {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.calculator-results ul li {
  font-size: 1rem;
  color: #b3b3b3;
}

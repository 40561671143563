/* Full-screen overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 25, 47, 0.8); /* Semi-transparent dark blue */
  backdrop-filter: blur(6px); /* Add blur effect for Web3 feel */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal box with Web3 style */
.modal-box {
  position: relative;
  background: linear-gradient(135deg, #1a1a2e, #162447); /* Gradient for depth */
  color: #ffffff;
  padding: 25px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.7),
    0 0 15px rgba(97, 218, 251, 0.6); /* Subtle glow for Web3 style */
  animation: fadeIn 0.3s ease-out, popIn 0.4s ease-out;
}

/* Close button */
.modal-close {
  position: absolute;
  top: 15px;
  right: 20px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.8rem;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.3s ease;
}

.modal-close:hover {
  transform: scale(1.2);
  color: #61dafb; /* Hover glow */
}

/* Modal title */
.modal-title {
  font-size: 1.8rem;
  margin-bottom: 15px;
  text-align: center;
  color: #61dafb; /* Highlighted color */
  text-shadow: 0 0 10px rgba(97, 218, 251, 0.6); /* Glow effect */
}

/* Modal content */
.modal-content {
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9); /* Softer text */
  text-align: center;
}

/* Animation for fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation for pop-in effect */
@keyframes popIn {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal-box {
    width: 95%;
    padding: 20px;
  }

  .modal-title {
    font-size: 1.5rem;
  }

  .modal-close {
    font-size: 1.5rem;
  }
}

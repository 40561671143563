/* General Page Layout */
.tokenomics {
  position: relative;
  background: #1a1a2e;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrolling due to particles */
}

/* Particles Background */
#tokenomics-tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Ensure particles are behind content */
  pointer-events: none; /* Prevent particles from blocking user interaction */
}

/* Hero Section */
.tokenomics-hero {
  text-align: center;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure hero section is above particles */
  position: relative;
  background: linear-gradient(135deg, #1a1a2e, #61dafb);
}

/* Individual Sections */
.total-supply-section,
.allocation-section,
.staking-section,
.burn-mint-section {
  position: relative;
  z-index: 1; /* Ensure content is above particles */
  padding: 40px 20px;
  background: rgba(20, 31, 32, 0); /* Semi-transparent background */
  margin-bottom: 0;
  border-radius: 0;
}

.airdrop-details {
  background: #1a1a2e;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
  color: #ffffff;
  text-align: center;
  margin: 20px auto;
  max-width: 600px;
}

.details-title {
  font-size: 1.8rem;
  color: #61dafb;
  margin-bottom: 20px;
  text-shadow: 0 2px 5px rgba(97, 218, 251, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.airdrop-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0f172a;
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.airdrop-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.airdrop-icon {
  color: #61dafb;
  font-size: 1.5rem;
}

.airdrop-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.airdrop-name {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
}

.airdrop-participants {
  font-size: 0.9rem;
  color: #dcdcdc;
}

.view-btn {
  background-color: #61dafb;
  color: #000;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.view-btn:hover {
  background-color: #5bc0de;
}

.airdrop-list {
    background: #1a1a2e;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
    color: #ffffff;
    text-align: center;
  }
  
  .airdrop-list h2 {
    font-size: 1.8rem;
    color: #61dafb;
    margin-bottom: 20px;
    text-shadow: 0 2px 5px rgba(97, 218, 251, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .loading-message,
  .no-airdrops-message {
    margin: 15px 0;
    color: #dcdcdc;
  }
  
  
  .airdrop-list-items {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .airdrop-item {
    background: #0f172a;
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .airdrop-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(97, 218, 251, 0.5);
  }
  
  .airdrop-link {
    text-decoration: none;
    color: #61dafb;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .airdrop-icon {
    font-size: 1.5rem;
    color: #61dafb;
    margin-right: 10px;
  }
  
  
.plans-hero {
    text-align: center;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #1a1a2e, #61dafb);
    padding: 20px;
    margin-bottom: 30px;
  }
  
  .plans-title {
    font-size: 3.5rem;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(135deg, #61dafb, #21a1f1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 15px;
  }
  
  .plans-description {
    font-size: 1.2rem;
    color: #dcdcdc;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  /* Main Container */
  .plans-container {
    background-color: #161b22;
    padding: 20px 0;
  }
  
  /* Plans Grid */
  .plans-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
  }
  
  /* Plan Card */
  .plan-card {
    background: #1a1f26;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    width: 300px;
    box-shadow: 0px 4px 15px rgba(97, 218, 251, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .plan-card:hover {
    box-shadow: 0px 8px 20px rgba(97, 218, 251, 0.5);
    transform: translateY(-5px);
  }
  
  .plan-icon {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #4caf50;
  }
  
  .plan-title {
    font-size: 1.5rem;
    color: #61dafb;
    margin-bottom: 10px;
  }
  
  .plan-description {
    font-size: 1rem;
    color: #b3b3b3;
    margin-bottom: 15px;
  }
  
  .plan-features {
    list-style: none;
    padding: 0;
  }
  
  .plan-features li {
    font-size: 0.9rem;
    color: #dcdcdc;
    margin-bottom: 5px;
  }
  
  /* Responsive Styling */
  @media screen and (max-width: 768px) {
    .plans-title {
      font-size: 2.5rem;
    }
  
    .plans-description {
      font-size: 1rem;
    }
  
    .plan-card {
      width: 90%;
    }
  }
  
/* InvestmentDetails.css */
.investment-details {
    font-family: "Inter", sans-serif;
    background: linear-gradient(135deg, #1a1a2e, #0f172a);
    color: white;
    padding: 40px 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    max-width: 800px;
    margin: 30px auto;
  }
  
  .investment-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    background: linear-gradient(135deg, #61dafb, #21a1f1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .investment-intro,
  .investment-summary {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .investment-benefits,
  .key-features {
    list-style: none;
    padding: 0;
    margin: 0 auto 20px auto;
    max-width: 600px;
  }
  
  .investment-benefits li,
  .key-features li {
    font-size: 1rem;
    margin: 15px 0;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    transition: transform 0.3s ease, background 0.3s ease;
  }
  
  .investment-benefits li:hover,
  .key-features li:hover {
    background: rgba(97, 218, 251, 0.1);
    transform: translateY(-5px);
  }
  
  .benefit-icon,
  .feature-icon {
    font-size: 1.5rem;
    color: #61dafb;
  }
  
  .key-features-title {
    font-size: 2rem;
    margin-bottom: 15px;
    text-align: center;
    background: linear-gradient(135deg, #4caf50, #21a1f1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
/* General Form Styles */
.auth-form {
  max-width: 400px;
  width: 100%; /* Ensure it adapts to smaller containers */
  margin: 0 auto;
  background: linear-gradient(135deg, #1a1a2e, #282c34);
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.7);
  text-align: center;
  color: #ffffff;
}

.auth-form h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #61dafb;
  font-weight: bold;
  text-transform: uppercase;
}

/* Input Fields */
.auth-form input {
  width: calc(100% - 20px); /* Maintain padding and avoid overflow */
  max-width: 360px; /* Consistent with the form's width */
  padding: 12px;
  margin: 15px auto;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  outline: none;
  transition: border 0.3s, background-color 0.3s;
}

.auth-form input:focus {
  border: 1px solid #61dafb;
  background-color: rgba(255, 255, 255, 0.2);
}

/* Button */
.auth-form button {
  width: calc(100% - 20px); /* Consistent with input fields */
  max-width: 360px; /* Consistent with input fields */
  padding: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(90deg, #61dafb, #21a1f1);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.auth-form button:disabled {
  background: rgba(255, 255, 255, 0.2);
  cursor: not-allowed;
}

.auth-form button:hover:not(:disabled) {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(33, 161, 241, 0.5);
}

/* Error and Success Messages */
.error-message,
.success-message {
  font-size: 1rem;
  margin: 10px 0;
  font-weight: bold;
}

.error-message {
  color: #ff6b6b;
}

.success-message {
  color: #4caf50;
}

/* Auth Links */
.auth-link {
  color: #61dafb;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
  font-weight: bold;
}

.auth-link:hover {
  color: #21a1f1;
  text-decoration: underline;
}

/* Password Container */
.password-container {
  position: relative;
  margin: 15px auto;
  max-width: 360px; /* Consistent with input fields */
}

.password-container input {
  width: calc(100% - 40px); /* Adjust for the icon */
  padding-right: 40px; /* Space for the toggle icon */
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.toggle-password:hover {
  color: #61dafb;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .auth-form {
    padding: 15px;
    width: 90%;
  }

  .auth-form h2 {
    font-size: 1.5rem;
  }

  .auth-form input,
  .auth-form button {
    font-size: 1rem;
  }
}

.footer {
  position: relative; /* Ensure content stays relative */
  z-index: 1;
  color: #ffffff;
  padding: 40px 20px;
  text-align: center;
  overflow: hidden; /* Prevent overflow from particles */
  background-color: #161b22; /* Ensure particles blend with footer */
}

.footer-particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Ensure particles are behind the content */
  pointer-events: none; /* Prevent interaction with the particles layer */
}

#tsparticles-footer {
  width: 100%;
  height: 100%;
}

.footer-container {
  position: relative;
  z-index: 1; /* Bring footer content above the particle background */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Branding Section */
.footer-brand {
  flex: 1;
  text-align: left;
  min-width: 250px;
}

.footer-brand h1 {
  font-size: 2rem;
  color: #61dafb;
}

.footer-brand p {
  font-size: 1rem;
  color: #b3b3b3;
}

/* Navigation Section */
.footer-links {
  flex: 1;
  min-width: 250px;
}

.footer-links h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #61dafb;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns by default */
  gap: 10px; /* Space between items */
}


.footer-links li {
  margin: 0; /* Remove unnecessary margin */
}

.footer-links li a {
  text-decoration: none;
  color: #b3b3b3;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: color 0.3s ease;
}

.footer-links li a:hover {
  color: #61dafb;
}

/* Social Media Section */
.footer-social {
  flex: 1;
  min-width: 250px;
}

.footer-social h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #61dafb;
}

.social-icons a {
  margin: 0 10px;
  color: #ffffff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #61dafb;
}

/* Copyright Section */
.footer-bottom {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #b3b3b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .footer-brand,
  .footer-links,
  .footer-social {
    text-align: center;
  }

  .footer-links ul {
    grid-template-columns: repeat(2, 1fr); /* Maintain two columns on smaller screens */
    justify-content: center; /* Center the grid items */
  }
}

